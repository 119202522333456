import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notifications: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor() { }

  // Save new notification
  saveNotification(payload: any): void {
    const currentNotifications = this._notifications.value;
    this._notifications.next([payload, ...currentNotifications]);
  }

  // Fetch notifications as an observable
  getNotifications(): Observable<any[]> {
    return this._notifications.asObservable();
  }
}
